<template>
  <div class="chart">
    <div class="title">
      七大产业求人倍率
    </div>
    <div class="chartbody">
      <div ref="ec1" id="echart" class="echartDiv"></div>
    </div>
    <!-- <div class="chartbody">
      <div ref="echart" id="echart" class="echartDiv"></div>
    </div>
    <div class="label">
      <div class="label-1"><span></span>2023年上半年</div>
      <div class="label-2"><span></span>2024年上半年</div>
    </div> -->
    <!-- <div class="charts">
      <div class="charts_d">
        <div ref="ec1" class="charts_d-chart"></div>
        <div class="charts_d-text">和平区就业市场繁荣指数</div>
      </div>
      <div class="charts_d">
        <div ref="ec2" class="charts_d-chart"></div>
        <div class="charts_d-text">和平区求职人数同比增长</div>
      </div>
      <div class="charts_d">
        <div ref="ec3" class="charts_d-chart"></div>
        <div class="charts_d-text">和平区薪资中位数同比增长</div>
      </div>
      <div class="charts_d">
        <div ref="ec4" class="charts_d-chart"></div>
        <div class="charts_d-text">和平区招聘需求同比增长</div>
      </div>
    </div> -->
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-liquidfill/src/liquidFill.js";
export default {
  data () {
    return {
      eventWarning: [],
      eventHandling: []
    }
  },
  watch: {
    '$store.state.page4Data' (newValue, oldVal) {
      const { rangeStructureDataVo } = newValue;
      this.eventWarning = [
        rangeStructureDataVo.empMarketProsperityIndex / 100,
        rangeStructureDataVo.jobseekerYoy / 100,
        rangeStructureDataVo.medianSalaryYoy / 100,
        rangeStructureDataVo.postDemandYoy / 100,
      ];
      this.getEcharts3DBar();
    }
  },
  methods: {
    getEcharts3DBar () {
      const myChart = echarts.init(this.$refs.ec1);
      var label = {
        normal: {
          formatter: (param) => {
            console.log("🚀 ~ getEcharts3DBar ~ param:", param)
            return (param.value * 100).toFixed(2) + '%'
          },
          textStyle: {
            color: '#ffffff',
            insideColor: '#ffffff',
            fontSize: 13
          }
        }
      };

      const option = {
        graphic: [
          //   {
          //   type: 'group',
          //   left: '5%',
          //   top: '80%',
          //   children: [{
          //     type: 'text',
          //     z: 100,
          //     left: 'center',
          //     top: 'top',
          //     style: {
          //       fill: '#FFFFFF',
          //       text: '和平区就业市场繁荣指数',
          //       font: '14px Microsoft YaHei'
          //     }
          //   }]
          // },
          {
            type: 'group',
            left: '11%',
            top: '80%',
            children: [{
              type: 'text',
              z: 100,
              left: 'center',
              top: 'top',
              style: {
                fill: '#FFFFFF',
                text: '和平区求职人数\n     同比增长',
                font: '14px Microsoft YaHei',
              }
            }]
          },
          {
            type: 'group',
            left: '40%',
            top: '80%',
            children: [{
              type: 'text',
              z: 100,
              left: 'center',
              top: 'top',
              style: {
                fill: '#FFFFFF',
                text: '和平区薪资中位数\n       同比增长',
                font: '14px Microsoft YaHei'
              }
            }]
          },
          {
            type: 'group',
            left: '71%',
            top: '80%',
            width: '80',
            children: [{
              type: 'text',
              z: 100,
              left: 'center',
              top: 'top',
              style: {
                fill: '#FFFFFF',
                text: '和平区招聘需求\n     同比增长',
                font: '14px Microsoft YaHei'
              }
            }]
          },
        ],
        series: [

          // {
          //   name: '水球图',
          //   type: 'liquidFill',
          //   radius: '40%',
          //   center: ['15%', '50%'],
          //   waveAnimation: 10, // 动画时长
          //   amplitude: 5, // 振幅
          //   data: [this.eventWarning[0], this.eventWarning[0] - (1 / 10)],
          //   itemStyle: { //渐变色设置
          //     normal: {
          //       color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          //         offset: 0,
          //         color: '#33FEFE'

          //       }, {
          //         offset: 1,
          //         color: '#028AFF'
          //       }]),
          //     }
          //   },
          //   color: {
          //     type: 'linear',
          //     x: 0,
          //     y: 0,
          //     x2: 1,
          //     y2: 1,
          //     colorStops: [{
          //       offset: 1,
          //       color: '#33FEFE'
          //     }, {
          //       offset: 0,
          //       color: '#028AFF'
          //     }],
          //     globalCoord: false
          //   },
          //   label,
          //   outline: {
          //     show: true,
          //     borderDistance: 0,
          //     itemStyle: {
          //       borderWidth: 3,
          //       borderColor: {
          //         type: 'linear',
          //         x: 0,
          //         y: 0,
          //         x2: 1,
          //         y2: 1,
          //         colorStops: [{
          //       offset: 1,
          //       color: '#33FEFE'
          //     }, {
          //       offset: 0,
          //       color: '#028AFF'
          //     }],
          //         globalCoord: false
          //       },
          //     }
          //   },
          //   backgroundStyle: {
          //     color: 'transparent'
          //   }
          // },
          {
            name: '水球图',
            type: 'liquidFill',
            radius: '40%',
            center: ['20%', '50%'],
            waveAnimation: 10, // 动画时长
            amplitude: 5, // 振幅
            data: [this.eventWarning[1], this.eventWarning[1]],
            itemStyle: { //渐变色设置
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#61D993'

                }, {
                  offset: 1,
                  color: '#10A74E'
                }]),
              }
            },
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 1,
              y2: 1,
              colorStops: [{
                offset: 1,
                color: '#61D993'
              }, {
                offset: 0,
                color: '#10A74E'
              }],
              globalCoord: false
            },
            label,
            outline: {
              show: true,
              borderDistance: 0,
              itemStyle: {
                borderWidth: 3,
                borderColor: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 1,
                  colorStops: [{
                    offset: 0,
                    color: '#61D993'
                  }, {
                    offset: 1,
                    color: '#10A74E'
                  }],
                  globalCoord: false
                },
              }
            },
            backgroundStyle: {
              color: 'transparent'
            },
          },
          {
            name: '水球图',
            type: 'liquidFill',
            radius: '40%',
            center: ['50%', '50%'],
            waveAnimation: 10, // 动画时长
            amplitude: 5, // 振幅
            data: [this.eventWarning[2], this.eventWarning[2] - (1 / 10)],
            itemStyle: { //渐变色设置
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#FFC266'

                }, {
                  offset: 1,
                  color: '#F57537'
                }]),
              }
            },
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 1,
              y2: 1,
              colorStops: [{
                offset: 1,
                color: '#FFC266'
              }, {
                offset: 0,
                color: '#F57537'
              }],
              globalCoord: false
            },
            label,
            outline: {
              show: true,
              borderDistance: 0,
              itemStyle: {
                borderWidth: 3,
                borderColor: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 1,
                  colorStops: [{
                    offset: 0,
                    color: '#FFC266'
                  }, {
                    offset: 1,
                    color: '#F57537'
                  }],
                  globalCoord: false
                },
              }
            },
            backgroundStyle: {
              color: 'transparent'
            }
          },
          {
            name: '水球图',
            type: 'liquidFill',
            radius: '40%',
            center: ['80%', '50%'],
            waveAnimation: 10, // 动画时长
            amplitude: 5, // 振幅
            data: [this.eventWarning[3], this.eventWarning[3] ],
            itemStyle: { //渐变色设置
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#F57537'

                }, {
                  offset: 1,
                  color: '#E94646'
                }]),
              }
            },
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 1,
              y2: 1,
              colorStops: [{
                offset: 1,
                color: '#F57537'
              }, {
                offset: 0,
                color: '#E94646'
              }],
              globalCoord: false
            },
            label,
            outline: {
              show: true,
              borderDistance: 0,
              itemStyle: {
                borderWidth: 3,
                borderColor: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 1,
                  colorStops: [{
                    offset: 0,
                    color: '#F57537'
                  }, {
                    offset: 1,
                    color: '#E94646'
                  }],
                  globalCoord: false
                },
              }
            },
            backgroundStyle: {
              color: 'transparent'
            }
          },
        ]

      };

      option && myChart.setOption(option);
    }
  }
}
</script>

<style lang="scss" scoped>
.charts {
  display: flex;
  height: 100%;

  &_d {
    width: 25%;
    height: 270px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-chart {
      width: 120px;
      height: 120px;
    }
  }
}

.chart {
  height: 314px;
  width: 100%;
  position: relative;

  .label {
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;

    .label-1 {
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;

      span {
        margin-right: 5px;
        display: inline-block;
        width: 12px;
        height: 8px;
        background: linear-gradient(180deg, #0088FF 0%, #47D1FF 99%);
        border-radius: 0px 0px 0px 0px;
      }
    }

    .label-2 {
      margin-left: 15px;
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;

      span {
        margin-right: 5px;
        display: inline-block;
        width: 12px;
        height: 8px;
        background: linear-gradient(180deg, #FDAA4C 4%, #FFF77B 96%);
        border-radius: 0px 0px 0px 0px;
      }
    }
  }

  .title {
    width: 100%;
    height: 44px;
    background: url("../../assets/page3/title-bj.png") no-repeat left;
    background-size: 220px 44px;
    line-height: 44px;
    text-indent: 26px;
    font-weight: 500;
    font-size: 18px;
    color: #E5F9FF;
  }

  .chartbody {
    width: 100%;
    height: 260px;
    display: flex;
    position: relative;



    .echartDiv {
      width: 100%;
      height: 230px;
    }
  }
}
</style>